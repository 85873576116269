import axios from "axios";

const useAxiosPublic = () => {
  const axiosPublic = axios.create({
    baseURL: `https://server.rumanails.com/api/v1`,
    withCredentials: true,
  });
  return axiosPublic;
};

export default useAxiosPublic;
