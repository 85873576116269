"use client";
import { createContext, useEffect, useState } from "react";
import {
  GithubAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import auth from "../Firebase/firebase.config";
import useAxiosPublic from "@/Hooks/useAxiosPublic";

export const AuthContext = createContext(null);

const FirebaseProvider = ({ children }) => {
  const [userData, SetUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const axiosPublic = useAxiosPublic();

  // Social Login Google
  const GoogleProvider = new GoogleAuthProvider();
  const GitHubProvider = new GithubAuthProvider();
  GitHubProvider.addScope("user:email");

  const GoogleSignIn = async () => {
    try {
      return await signInWithPopup(auth, GoogleProvider);
    } catch (error) {
      console.error("Google sign-in failed:", error);
      throw error;
    }
  };

  const handleLogOut = async () => {
    try {
      // Sign out the user
      await axiosPublic.post("/logout-user/");

      SetUserData(null);
      setLoading(false);
    } catch (error) {
      console.error("Error during sign out:", error);
    }
  };

  // to persist current user
  useEffect(() => {
    // Custom function to persist the authenticated user
    const persistCustomUser = async () => {
      try {
        // Call your custom API to fetch user data
        const res = await axiosPublic.get("/get-user-info/");

        if (res?.data?.status_code === 200 && res?.data?.user_info) {
          // If user exists, update the Redux state
          SetUserData(res?.data?.user_info);
          setLoading(false);
        } else {
          // Handle cases where user data isn't found or valid
          SetUserData(res?.data?.user_info);
          setLoading(false);
        }
      } catch (error) {
        // Handle API errors
        console.error("Custom user persistence error:", error);
        SetUserData(null);
        setLoading(false);
      }
    };

    // Call the persistCustomUser function on component mount
    persistCustomUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); /// empty dependence to make sure the should call only one time per render

  // send the value to the Context //I mean entire project
  const value = {
    userData,
    loading,
    SetUserData,
    setLoading,
    GoogleSignIn,
    handleLogOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default FirebaseProvider;
